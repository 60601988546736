<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
          <span
            v-if="contributionRangeId"
            class="body-2"
          >
            <i>#{{ contributionRange.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="initialIndex"
              >
                <v-text-field
                  v-model="contributionRange.initialIndex"
                  v-mask="'#,##########'"
                  label="Índice Inicial"
                  :error-messages="errors"
                  hide-details="auto"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="finalIndex"
              >
                <v-text-field
                  v-model="contributionRange.finalIndex"
                  v-mask="'#,##########'"
                  label="Índice Final"
                  :error-messages="errors"
                  hide-details="auto"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="year"
              >
                <v-text-field
                  v-model="contributionRange.year"
                  v-mask="'####'"
                  label="Ano"
                  :error-messages="errors"
                  hide-details="auto"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="value"
              >
                <v-text-field
                  v-model="contributionRange.value"
                  v-currency="{
                    currency: 'BRL',
                    locale: 'pt-BR',
                    distractionFree: false,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false
                  }"
                  hide-details="auto"
                  label="Valor"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import contributionsRangesApi from '@/api/contributions-ranges'
  import currencyFilter from '@/utils/filters/currency'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      contributionRangeId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        contributionRange: {},
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      title () {
        return this.contributionRangeId ? 'Editar' : 'Adicionar'
      }
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
    },

    methods: {

      async load () {
        try {
          this.contributionRange = {
            initialIndex: null,
            finalIndex: null,
            year: null,
            value: null,
          }

          if (!this.contributionRangeId) {
            return
          }

          this.loading = true

          const response = await contributionsRangesApi.get(this.contributionRangeId)

          this.contributionRange = response.data.contributionRange
          this.contributionRange.value = currencyFilter(this.contributionRange.value)
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          const contributionRange = { ...this.contributionRange }

          contributionRange.initialIndex = contributionRange.initialIndex ? parseFloat(contributionRange.initialIndex.replace(',', '.')) : null
          contributionRange.finalIndex = contributionRange.finalIndex ? parseFloat(contributionRange.finalIndex.replace(',', '.')) : null
          contributionRange.value = contributionRange.value ? parseInt(contributionRange.value.replace(/\D/g, '')) / 100 : null

          if (this.contributionRangeId) {
            await contributionsRangesApi.update(this.contributionRangeId, contributionRange)
          } else {
            await contributionsRangesApi.insert(contributionRange)
          }

          this.show = false
          this.$snackbar.show({
            color: 'success',
            message: this.contributionRangeId ? this.$messages._('update_success') : this.$messages._('new_success')
          })
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
