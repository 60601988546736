var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.contributionRangeId)?_c('span',{staticClass:"body-2"},[_c('i',[_vm._v("#"+_vm._s(_vm.contributionRange.id))])]):_vm._e()]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"initialIndex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#,##########'),expression:"'#,##########'"}],attrs:{"label":"Índice Inicial","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.contributionRange.initialIndex),callback:function ($$v) {_vm.$set(_vm.contributionRange, "initialIndex", $$v)},expression:"contributionRange.initialIndex"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"vid":"finalIndex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#,##########'),expression:"'#,##########'"}],attrs:{"label":"Índice Final","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.contributionRange.finalIndex),callback:function ($$v) {_vm.$set(_vm.contributionRange, "finalIndex", $$v)},expression:"contributionRange.finalIndex"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"label":"Ano","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.contributionRange.year),callback:function ($$v) {_vm.$set(_vm.contributionRange, "year", $$v)},expression:"contributionRange.year"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"vid":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
                  currency: 'BRL',
                  locale: 'pt-BR',
                  distractionFree: false,
                  autoDecimalMode: true,
                  valueRange: { min: 0 },
                  allowNegative: false
                }),expression:"{\n                  currency: 'BRL',\n                  locale: 'pt-BR',\n                  distractionFree: false,\n                  autoDecimalMode: true,\n                  valueRange: { min: 0 },\n                  allowNegative: false\n                }"}],attrs:{"hide-details":"auto","label":"Valor","error-messages":errors},model:{value:(_vm.contributionRange.value),callback:function ($$v) {_vm.$set(_vm.contributionRange, "value", $$v)},expression:"contributionRange.value"}})]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.loading)?_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" Salvar ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }